import { h } from 'preact';
import htm from 'htm';

import { palette } from '../../branding';

const html = htm.bind(h);

const LogoText = ({ alt = false }) => {
	const style = {
		'font-size': 40,
		'text-align': 'center',
		'font-family': 'Planet Gamers',
		color: palette.primary2,
	};
	if (alt) {
		style.color = palette.primary;
	}

	return html`<h1 style=${style}>
		Norwich <br />
		Games Con
	</h1>`;
};

const Shield = ({ letters = true, width, height, alt }) => {
	let calcWidth = width;
	if (width === undefined && height === undefined) {
		calcWidth = '100%';
	}

	let c1 = palette.primary;
	let c2 = palette.primary2;
	let c2Light = palette.primary2_lightened;
	let gradientName = 'shield_logo_gradient';

	if (alt === true) {
		// Invert colours
		c1 = palette.primary2;
		c2 = palette.primary;
		c2Light = palette.primary_lightened;
		gradientName = 'shield_logo_gradient_alt';
	}

	return html`
		<svg
			width="${calcWidth}"
			height="${height}"
			viewBox="0 0 139 194"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4V138.375C0 146.869 4.31238 154.781 11.4503 159.385L59.8522 190.6C65.4282 194.196 72.5868 194.22 78.1862 190.66L127.412 159.367C134.629 154.779 139 146.82 139 138.269V4C139 1.79086 137.209 0 135 0H116.5C114.291 0 112.5 1.79086 112.5 4V11.5C112.5 13.7091 110.709 15.5 108.5 15.5H87.5C85.2909 15.5 83.5 13.7091 83.5 11.5V4C83.5 1.79086 81.7091 0 79.5 0H59.5C57.2909 0 55.5 1.79086 55.5 4V11.5C55.5 13.7091 53.7091 15.5 51.5 15.5H31C28.7909 15.5 27 13.7091 27 11.5V4C27 1.79086 25.2091 0 23 0H4C1.79086 0 0 1.79086 0 4Z"
				fill="url(#${gradientName})"
			/>
			<defs>
				<linearGradient
					id=${gradientName}
					x1="69.5"
					y1="0"
					x2="70"
					y2="197"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color=${c2Light} />
					<stop offset="1" stop-color=${c2} />
				</linearGradient>
			</defs>

			${letters
			&& html`
				<svg
					width="90"
					viewBox="0 0 73 100"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					x="20"
				>
					<path
						d="M7.16952 0.888794L26.57 24.9352V7.27053C26.57 5.5347 26.4169 4.15624 26.1105 3.13517C25.8382 2.08005 25.1065 1.5525 23.9152 1.5525V0.888794H35.3513V1.5525C34.7046 1.5525 34.1941 1.72267 33.8197 2.06303C33.4453 2.36936 33.173 2.82884 33.0028 3.44149C32.8667 4.0201 32.7816 4.71784 32.7475 5.5347C32.7135 6.35156 32.6965 7.27053 32.6965 8.29161V29.2237C32.6965 30.2448 32.7135 31.1638 32.7475 31.9806C32.7816 32.7975 32.8667 33.5122 33.0028 34.1249C33.173 34.7035 33.4453 35.163 33.8197 35.5033C34.1941 35.8097 34.7046 35.9628 35.3513 35.9628V36.6265H28.408L9.67117 13.6523V29.2237C9.67117 30.2448 9.68818 31.1638 9.72222 31.9806C9.75626 32.7975 9.84135 33.5122 9.97749 34.1249C10.1477 34.7035 10.42 35.163 10.7944 35.5033C11.1687 35.8097 11.6793 35.9628 12.326 35.9628V36.6265H0.889893V35.9628C1.57061 35.9628 2.08115 35.8097 2.42151 35.5033C2.79591 35.163 3.05118 34.7035 3.18732 34.1249C3.3575 33.5122 3.45961 32.7975 3.49364 31.9806C3.52768 31.1638 3.5447 30.2448 3.5447 29.2237V8.29161C3.5447 7.27053 3.52768 6.35156 3.49364 5.5347C3.45961 4.71784 3.3575 4.0201 3.18732 3.44149C3.05118 2.82884 2.79591 2.36936 2.42151 2.06303C2.08115 1.72267 1.57061 1.5525 0.889893 1.5525V0.888794H7.16952Z"
						fill=${c1}
					/>
					<path
						d="M43.4944 68.5033C40.4312 68.5033 37.7423 67.9758 35.4279 66.9206C33.1134 65.8315 31.1904 64.419 29.6588 62.6832C28.1272 60.9133 26.9699 58.9222 26.1871 56.7099C25.4383 54.4635 25.0639 52.1831 25.0639 49.8686C25.0639 47.5202 25.4383 45.2398 26.1871 43.0274C26.9699 40.781 28.1272 38.7899 29.6588 37.0541C31.1904 35.3183 33.1134 33.9228 35.4279 32.8677C37.7423 31.7785 40.4312 31.234 43.4944 31.234C45.3664 31.234 47.2213 31.5573 49.0593 32.204C50.8972 32.8166 52.582 33.6846 54.1136 34.8077H54.1647V34.8588L52.1736 45.7843H51.5099C51.5099 45.0355 51.4588 44.0996 51.3567 42.9764C51.1525 41.9893 50.7781 41.1384 50.2335 40.4237C49.723 39.7089 49.1103 39.1303 48.3956 38.6878C47.6808 38.2113 46.881 37.871 45.996 37.6668C45.1111 37.4285 44.1921 37.3094 43.2391 37.3094H43.0349C41.0949 37.3775 39.4101 37.7859 37.9806 38.5347C36.5511 39.2494 35.3428 40.2024 34.3557 41.3937C33.4027 42.5509 32.688 43.8783 32.2115 45.3759C31.735 46.8394 31.4967 48.337 31.4967 49.8686C31.4967 51.4343 31.752 52.9829 32.2625 54.5145C32.7731 56.0121 33.5219 57.3565 34.5089 58.5478C35.53 59.705 36.7893 60.641 38.2869 61.3558C39.7845 62.0705 41.5373 62.4279 43.5454 62.4279C44.9749 62.4279 46.1662 62.2577 47.1192 61.9174C48.1063 61.577 48.9061 61.2026 49.5187 60.7942V53.953H48.5487C47.5276 53.953 46.6087 53.97 45.7918 54.004C44.975 54.038 44.2602 54.1402 43.6475 54.3103C43.0689 54.4465 42.6095 54.7017 42.2691 55.0761C41.9628 55.4165 41.8096 55.927 41.8096 56.6078H41.1459V48.2349H55.6963V64.2148C54.1987 65.4061 52.3778 66.4271 50.2335 67.278C48.1233 68.0949 45.8769 68.5033 43.4944 68.5033Z"
						fill=${c1}
					/>
					<path
						d="M72.5122 65.9698L70.5211 76.8953H69.8574C69.8574 76.1465 69.8063 75.2105 69.7042 74.0873C69.4659 73.0663 69.0745 72.2154 68.53 71.5346C68.0194 70.8199 67.4068 70.2413 66.692 69.7988C66.0113 69.3223 65.2795 68.982 64.4967 68.7777C63.7139 68.5735 62.9481 68.4544 62.1993 68.4204H62.0461C60.0039 68.4204 58.2171 68.7777 56.6854 69.4925C55.1879 70.2072 53.9285 71.1603 52.9075 72.3515C51.8864 73.5087 51.1206 74.8361 50.61 76.3337C50.0995 77.8313 49.8442 79.3799 49.8442 80.9796C49.8442 82.5453 50.0995 84.0939 50.61 85.6255C51.1206 87.1231 51.8694 88.4675 52.8564 89.6588C53.8775 90.816 55.1368 91.752 56.6344 92.4668C58.132 93.1815 59.8848 93.5389 61.8929 93.5389C62.7779 93.5389 63.6798 93.4368 64.5988 93.2326C65.5518 92.9943 66.4367 92.654 67.2536 92.2115C68.0705 91.769 68.7852 91.2074 69.3979 90.5267C70.0445 89.846 70.487 89.0461 70.7253 88.1272V87.7698H71.44L72.5122 95.9895V96.0405H72.4611C71.5421 96.7893 70.6402 97.402 69.7552 97.8785C68.9044 98.355 68.0194 98.7123 67.1004 98.9506C66.2155 99.2229 65.2795 99.3931 64.2925 99.4611C63.3054 99.5632 62.2333 99.6143 61.0761 99.6143C58.1149 99.5122 55.5282 98.9166 53.3159 97.8274C51.1376 96.7383 49.2996 95.3258 47.8021 93.5899C46.3385 91.8541 45.2323 89.897 44.4836 87.7187C43.7688 85.5064 43.4114 83.26 43.4114 80.9796C43.4114 78.6311 43.7858 76.3507 44.5346 74.1384C45.3174 71.892 46.4747 69.9009 48.0063 68.1651C49.5379 66.4293 51.4609 65.0338 53.7754 63.9787C56.0898 62.8895 58.7787 62.3449 61.8419 62.3449C63.7819 62.3449 65.6539 62.6683 67.4578 63.315C69.2617 63.9276 70.9295 64.7955 72.4611 65.9187H72.5122V65.9698Z"
						fill=${c1}
					/>
				</svg>
			`}
		</svg>
	`;
};

export { Shield, LogoText };

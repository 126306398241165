import { h } from 'preact';
import htm from 'htm';

import { palette } from '../branding';
import { Band } from '../atoms/band';
import { Section, SectionHead, SectionNarrow } from '../molecules/section';
import getInvolvedHead from './getinvolved-head.jpg';

const html = htm.bind(h);

export default () => html`
	<${Section} banner backgroundImage=${getInvolvedHead}>
		<${SectionHead} text="Get Involved" shadow />
	</${Section}>
	<${Section} just>
		<${SectionHead} text="ExhIbIt­ors" />
		<${SectionNarrow}>
			<p>Are you interested in exhibiting with us in 2024? Exhibitor spaces for 2024 are <b><i>now available</i></b> to book. Contact <a href="mailto: stacey@norwichgames.uk">Stacey Sanderson</a> and we'll send you over an infopack, pricing information and everything else you need!</p>

			<p>In 2023 we hosted 60 exhibitors and 1,350 attendees for a great day of games, and we'd love to have you join us for a BIGGER and MORE AMBITIOUS second year!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section} just alt>
		<${SectionHead} text="RPG Game Masters" />
		<${SectionNarrow}>
			<p>In 2024 we'll be hosting another big day of games, including some special guests and some games in an extra special location. RPG event signups are now open, so if you're interested in hosting a roleplaying game in 2024 please fill out this <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScY9lR5dxwAHkNFwMAQnxtPWBs9xntUNtaZpv56tIdZYeIpeQ/viewform">RPG Event Submission form</a>.</p>

			<p>RPG Game Masters get free VIP entry to the convention, and we'll also give you £10 for every game you run to make sure you're well fed (or spend it all on beer, that works too).</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section} just>
		<${SectionHead} text="Volunt­eers" />
		<${SectionNarrow}>
			<p>NGC is all about community, and our group of tightly-knit volunteers are the friendliest around. If you're interested in joining us to help make NGC a success please fill out this <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScQl26ck3qmjRR_HZTkOWXo_Z3Em6bxKRS8iYl9oPsf2Zdd6Q/viewform">Volunteer Signup form</a>. We ask that volunteers be willing to give around 6 hours of their time on the day, but it's unlikely we'll be asking for more than about 4 hours of light work. All volunteers will be paired with someone who has previously been involved with the organisation and running of Norwich Games Convention.</p>

			<p>Volunteers get VIP entry to the convention, exclusive swag, paid lunch, and anything else we can think of to say thankyou!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Band} />
`;

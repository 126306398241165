import { h } from 'preact';
import { useState } from 'preact/hooks';
import htm from 'htm';

import { palette } from '../../branding';
import background2 from '../../img/background2.png';

const html = htm.bind(h);

const BuyTicketsButton = () => {
	const [hover, setHover] = useState(false);

	const styles = {
		normal: {
			'font-family': 'Planet Gamers',
			'font-size': 40,
			'text-align': 'center',
			'text-decoration': 'none',
			'font-weight': 'normal',
			color: palette.accent,
			width: '9em',
			'max-width': '95%',
			'min-height': '1em',
			'padding': '0.5em 0em 0.3em 0em',
			background: palette.primary,
			'border-radius': 5,
			cursor: 'pointer',
			display: 'block',

			'-webkit-user-select': 'none',
			'-moz-user-select': 'none',
			'-ms-user-select': 'none',
			'user-select': 'none',

			transition: 'background 0.5s,color 0.5s',
		},
	};
	styles.hover = {
		...styles.normal,
		color: palette.primary2,
		background: palette.accent,
	};
	const style = hover ? styles.hover : styles.normal;

	const bannerStyle = {
		display: 'flex',
		'justify-content': 'center',
		'flex-direction': 'column',
		'align-items': 'center',
		'min-height': 80,
		background: `url(${background2}) ${palette.primary2}`,
		padding: '20px 0px',
	};
	return html`<div style=${bannerStyle}>
		<a
			style=${style}
			onMouseOver=${() => setHover(true)}
			onMouseLeave=${() => setHover(false)}
			href="https://www.ticketebo.co.uk/norwichgamescon"
			target="_blank"
		>
			Buy Tickets
		</a>
	</div>`
};

export { BuyTicketsButton };

import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import htm from 'htm';

const html = htm.bind(h);

// ImageList takes objects of the format { img: string, url: string, name: name }
const ImageList = ({
	width = 153,
	justifyContent = 'center',
	keepSquare,
	background,
	minItems = 1,

	items,
}) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWindowWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const style = {
		display: 'flex',
		'justify-content': justifyContent,
		'flex-direction': 'row',
		'flex-wrap': 'wrap',
		'align-items': 'stretch',
		'gap': 20,
		'margin': '20px 0px',
		'font-family': 'Planet Gamers',
		'font-size': windowWidth >= 720 ? 20 : 12,
		'width': '100%',
	};
	const itemStyle = {
		display: 'flex',
		'justify-content': 'center',
		'align-items': 'center',
		'width': width,
		'aspect-ratio': keepSquare ? '1' : null,
		'max-width': `calc((100% - 40px) / ${minItems})`,
		'background': background,
	};
	const imgStyle = {
		'width': width,
		'max-width': '100%',
		'max-height': '100%',
	};
	const pStyle = {
		'width': width,
		'max-width': '100%',
		'max-height': '100%',
		'text-align': 'center',
		padding: 5,
	};

	return html`<div style=${style}>
		${items.map((item) => html`
			<a target="_blank" href=${item.url} style=${itemStyle}>
				${item.img && html`<img style=${imgStyle} src=${item.img} alt=${item.name} />`}
				${!item.img && html`<p style=${pStyle}>${item.name}</p>`}
			</a>
		`)}
	</div>`
};

export { ImageList };

import { h } from 'preact';
import htm from 'htm';

import { palette } from '../../branding';

const html = htm.bind(h);

const Band = ({ colour = palette.primary, big }) => {
	// Shared
	let style = {
		padding: 0,
		margin: 0,
		background: colour,
		width: '100%',
		height: big ? 80 : 40,
	};

	return html`<div style=${style}></div>`;
};

export { Band };

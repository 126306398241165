import { Router } from 'preact-router';
import { h, render } from 'preact';
import htm from 'htm';

import routes from './routes';
import { Menu } from './molecules/menu';
import { Section, SectionHead } from './molecules/section';
import background from './img/background.png';

const html = htm.bind(h);

const outerStyle = {
	display: 'flex',
	'justify-content': 'center',
	'flex-direction': 'row',
	'flex-wrap': 'nowrap',
	  background: `url(${background})`,
	'min-height': '100%',
};

const innerStyle = {
	'max-width': 1100,
	width: '100%',
	position: 'relative',
};

const contentStyle = {
	'margin-top': 100,
};

const Main = () => html`
	<div style=${outerStyle}>
		<${Menu} />
		<div style=${innerStyle}>
			<div style=${contentStyle}>
				<${Router}>
					${routes.filter(r => r.page).map((route) => html`
						<${route.page} path=${route.path} />
					`)}
					<div default>
						<${Section}>
							<${SectionHead} text="404" shadow />
						</${Section}>
					</div>
				</${Router}>
			</div>
		</div>
	</div>
`;

document.addEventListener('DOMContentLoaded', () => {
	render(html`<${Main} />`, document.body);
});

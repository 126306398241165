import { h } from 'preact';
import htm from 'htm';

import { palette } from '../../branding';

const html = htm.bind(h);

const ItemTile = ({
	children,
	background = palette.primary,
}) => {
	const style = {
		width: '100%',
		display: 'flex',
		'justify-content': 'center',
		'align-items': 'stretch',
		'flex-direction': 'row',
		'flex-wrap': 'wrap',
		'box-sizing': 'border-box',
		padding: 10,
		gap: 5,

		background,
	};

	return html`<div style=${style}>
		${children}
	</div>`
};

const ItemTileCard = ({
	children,
	background = palette.secondary,
	border = `2px dashed ${palette.primary2}`,
}) => {
	const outerStyle = {
		padding: 5,
		background: background,
		'flex-grow': '1',
		width: 400,
	};

	const style = {
		width: '100%',
		height: '100%',
		'box-sizing': 'border-box',
		padding: 20,

		background,
		border,
	};

	return html`<div style=${outerStyle}>
		<div style=${style}>
			${children}
		</div>
	</div>`
};

const ItemTileHead = ({ text }) => {
	const style = {
		color: palette.primary2,
		'font-family': 'Planet Gamers',
		'font-size': 32,
		width: '100%',
		'text-align': 'center',
	};

	return html`<div style=${style}>${text}</div> `;
};

export { ItemTile, ItemTileCard, ItemTileHead };

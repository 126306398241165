import { h } from 'preact';
import { useState } from 'preact/hooks';
import htm from 'htm';

import { palette } from '../branding';
import { Band } from '../atoms/band';
import { Section, SectionHead } from '../molecules/section';
import { Shield, LogoText } from '../atoms/logo';
import { SocialFacebook, SocialInstagram } from '../atoms/social-media';
import { ImageList } from '../molecules/image-list';
import { BuyTicketsButton } from '../molecules/buy-tickets';

import homeHead from './home-head.jpg';

const html = htm.bind(h);

const partners = [{
	img: require('../img/partners/nua.png'),
	url: 'https://norwichuni.ac.uk',
	name: 'Norwich University of the Arts',
}, {
	img: require('../img/partners/treefrog.png'),
	url: 'https://www.treefrog.uk.com',
	name: 'Treefrog',
}, {
	img: require('../img/partners/oak.png'),
	url: 'https://theoakcircuscentre.org',
	name: 'The Oak Circus Centre',
}, {
	img: require('../img/partners/castle.png'),
	url: 'https://www.museums.norfolk.gov.uk/norwich-castle',
	name: 'Norwich Castle',
}];

const charities = [{
	img: require('../img/charities/tbf.png'),
	url: 'https://benjaminfoundation.co.uk',
	name: 'The Benjamin Foundation',
}, {
	img: require('../img/charities/ftp.png'),
	url: 'https://linktr.ee/ftpnorwich',
	name: 'Feed The People',
}];

const sponsors = [{
	img: require('../img/sponsors/evil-genius-games.png'),
	url: 'https://evilgeniusgames.com',
	name: 'Evil Genius Games',
}];

const exhibitors = [{
	// 06
	img: require('../img/exhibitors/Abi_Stevens_Art.png'),
	name: 'Abi Stevens Art',
	url: 'https://www.abistevens.com',
}, {
	// 31
	img: require('../img/exhibitors/Athena_Games.jpg'),
	name: 'Athena Games',
	url: 'https://athenagames.com',
}, {
	// 32
	img: require('../img/exhibitors/Badgers_Games.png'),
	name: 'Badgers Games',
	url: 'https://www.badgersgames.co.uk',
}, {
	// 37
	img: require('../img/exhibitors/Beastly_Beverages.jpg'),
	name: 'Beastly Beverages',
	url: 'https://www.beastlybeverages.com',
}, {
	// 48
	img: require('../img/exhibitors/Ben_Allen_Ink.png'),
	name: 'Ben Allen Ink',
	url: 'https://www.instagram.com/benecilin',
}, {
	// 30
	img: require('../img/exhibitors/Beth_Knits_and_Stuff.png'),
	name: 'Beth Knits and Stuff',
	url: 'https://www.instagram.com/BethKnitsAndStuff',
}, {
	// 22
	img: require('../img/exhibitors/Bindwood_Gaming.png'),
	name: 'Bindwood Gaming',
	url: 'https://www.bindwoodgaming.co.uk',
}, {
	// 27
	img: require('../img/exhibitors/Cairney_Cards.png'),
	name: 'Cairney Cards',
	url: 'https://www.facebook.com/CairneyCardsMTG',
}, {
	// 12
	img: require('../img/exhibitors/Card_Noir.png'),
	name: 'Card Noir',
	url: 'https://www.crownofash.com',
}, {
	// 38
	img: require('../img/exhibitors/Cat_Outta_Hell.png'),
	name: 'Cat Outta Hell',
	url: 'https://www.catouttahell.co.uk',
}, {
	// --
	img: require('../img/exhibitors/Caterpie_Cards.png'),
	name: 'Caterpie Cards',
	url: 'https://www.ebay.co.uk/str/caterpiecards',
}, {
	// 25
	// img: None,
	name: 'Celes­tial Games',
	url: 'https://www.celestialgames.uk',
}, {
	// 17
	img: require('../img/exhibitors/Clockwork_Arcana.png'),
	name: 'Clockwork Arcana',
	url: 'https://www.clockworkarcana.co.uk',
}, {
	// 49
	img: require('../img/exhibitors/Compose_Dream_Games.jpg'),
	name: 'Compose Dream Games',
	url: 'https://www.composedreamgames.com',
}, {
	// 26
	img: require('../img/exhibitors/Critit.jpg'),
	name: 'Critit',
	url: 'https://critit.co.uk',
}, {
	// 40
	img: require('../img/exhibitors/Damnation_Party.png'),
	name: 'Damnation Party',
	url: 'https://www.etsy.com/uk/shop/DamnationParty',
}, {
	// 41
	img: require('../img/exhibitors/Dice_Average.png'),
	name: 'Dice Average RPG',
	url: 'https://twitter.com/diceaveragejack',
}, {
	// 03
	img: require('../img/exhibitors/The_Enchanted_Needle.png'),
	name: 'The Enchanted Needle',
	url: 'https://www.etsy.com/fr/shop/EnchantedNeedleThe',
}, {
	// 18
	img: require('../img/exhibitors/Ex_Stasis_Games.png'),
	name: 'Ex Stasis Games',
	url: 'https://www.exstasisgames.com',
}, {
	// 39
	img: require('../img/exhibitors/Figmata.jpg'),
	name: 'Figmata',
	url: 'https://www.instagram.com/figmata_',
}, {
	// 04
	img: require('../img/exhibitors/ForMySin_Designs.png'),
	name: 'ForMySin Designs',
	url: 'https://modelsandminis.co.uk',
}, {
	// 24
	img: require('../img/exhibitors/Games_By_John_Sherman.jpg'),
	name: 'Games By John Sherman',
	// url: None,
}, {
	// 44
	img: require('../img/exhibitors/Games_Table.png'),
	name: 'The Games Table',
	url: 'https://www.thegamestable.co.uk',
}, {
	// 35
	img: require('../img/exhibitors/The_Inky_Gauntlet.png'),
	name: 'The Inky Gauntlet',
	url: 'https://www.theinkygauntlet.com',
}, {
	// --
	img: require('../img/exhibitors/Lantern_Man.png'),
	name: 'Lantern Man',
	url: 'https://linktr.ee/lanternman',
}, {
	// 15
	img: require('../img/exhibitors/Lazy_Bug_Rugs.png'),
	name: 'Lazy Bug Rugs',
	url: 'https://www.facebook.com/lazybugrugs',
}, {
	// 08
	img: require('../img/exhibitors/LuteLoot.png'),
	name: 'LuteLoot',
	url: 'https://www.tiktok.com/@lutelootdnd',
}, {
	// 20
	img: require('../img/exhibitors/Melsonian_Arts_Council.png'),
	name: 'Melsonian Arts Council',
	url: 'https://www.melsonia.com',
}, {
	// 09
	img: require('../img/exhibitors/Mottokrosh_Machinations.png'),
	name: 'Mottokrosh Machinations',
	url: 'https://mottokrosh.com',
}, {
	// 19
	img: require('../img/exhibitors/Mint_And_Mustard.png'),
	name: 'Mint & Mustard',
	url: 'https://www.instagram.com/mintandmustard',
}, {
	// 10
	img: require('../img/exhibitors/Mystery_Dice_Goblin.png'),
	name: 'Mystery Dice Goblin',
	url: 'https://mysterydicegoblin.com',
}, {
	// 42
	img: require('../img/exhibitors/Nesshin_Games.png'),
	name: 'Nesshin Games',
	url: 'https://www.instagram.com/nesshingames',
}, {
	// 28
	img: require('../img/exhibitors/Nomads.png'),
	name: 'Nomads',
	url: 'https://www.etsy.com/uk/shop/NomadsGame',
}, {
	// 14
	img: require('../img/exhibitors/Onebold_Studio.png'),
	name: 'Onebold Studio',
	url: 'https://linktr.ee/oneboldstudio',
}, {
	// 16
	img: require('../img/exhibitors/Pocket_Forge.png'),
	name: 'The Pocket Forge',
	url: 'https://www.instagram.com/thepocketforge',
}, {
	// 21
	img: require('../img/exhibitors/Rachael_Squonk.jpg'),
	name: 'Rachael Squonk',
	url: 'https://www.etsy.com/uk/shop/RachaelSquonk',
}, {
	// 33
	img: require('../img/exhibitors/ROK_Minis.png'),
	name: 'ROK Minis',
	url: 'https://www.rokminis.com',
}, {
	// 47
	img: require('../img/exhibitors/Rowan_Rook_and_Decard.png'),
	name: 'Rowan Rook and Decard',
	url: 'https://rowanrookanddecard.com',
}, {
	// 23
	img: require('../img/exhibitors/Runaway_Thread.png'),
	name: 'Runaway Thread',
	url: 'https://runawaythread.co.uk',
}, {
	// 05
	img: require('../img/exhibitors/RYFT_Games.png'),
	name: 'RYFT Games',
	url: 'https://www.ryftcardbattle.com',
}, {
	// 34
	img: require('../img/exhibitors/S_C_Games.png'),
	name: 'S C Games',
	url: 'https://www.facebook.com/SCGames2019',
}, {
	// 45
	img: require('../img/exhibitors/Shades_Of_Vengeance.png'),
	name: 'Shades Of Vengeance',
	url: 'https://www.shadesofvengeance.com',
}, {
	// 29
	img: require('../img/exhibitors/Shrike.jpg'),
	name: 'Shrike Studios',
	url: 'https://www.shrikestudio.co.uk',
}, {
	// --
	img: require('../img/exhibitors/Slice_+_Dice.png'),
	name: 'Slice + Dice',
	url: 'https://www.sliceanddice.cafe',
}, {
	// 07
	img: require('../img/exhibitors/Stuff_By_Bez.png'),
	name: 'Stuff By Bez',
	url: 'https://linktr.ee/stuffByBez',
}, {
	// 45
	img: require('../img/exhibitors/Twelve_Pins_Press.webp'),
	name: 'Twelve Pins Press',
	url: 'https://twelvepinspress.com',
}, {
	// 02
	img: require('../img/exhibitors/Vivid_Seams.png'),
	name: 'Vivid Seams',
	url: 'https://www.etsy.com/uk/shop/VividSeamsUK',
}, {
	// 13
	img: require('../img/exhibitors/Wayward_Adventurers_Guild.png'),
	name: 'The Wayward Adventurers Guild',
	url: 'https://www.waywardadventurers.co.uk',
}, {
	// 01
	img: require('../img/exhibitors/Yiru_Sann.png'),
	name: 'Yirusann',
	url: 'https://www.yirusann.com',
}, {
	// XX
	img: require('../img/exhibitors/Zatu.png'),
	name: 'Zatu Games',
	url: 'https://www.board-game.co.uk',
}, {
	// 50
	img: require('../img/exhibitors/Marshmallow_Kitty_Shop.png'),
	name: 'Marshmallow Kitty Shop',
	url: 'https://www.etsy.com/uk/shop/Marshmallowkittyshop',
}, {
	// 51
	img: require('../img/exhibitors/DestinyQuest.jpg'),
	name: 'DestinyQuest',
	url: 'http://www.destiny-quest.com',
}, {
	// 53
	img: require('../img/exhibitors/DeerlyBelovedBakery.png'),
	name: 'Deerly Beloved Bakery',
	url: 'https://www.deerlybelovedbakery.com',
}, {
	// 54
	// img: None
	name: 'Huw Church­man',
	url: 'https://linktr.ee/huwchurchman',
}, {
	// 55
	img: require('../img/exhibitors/ShirtsAndMerch.png'),
	name: 'Shirts And Merch',
	url: 'https://shirtsandmerchstudio.etsy.com',
}, {
	// 56
	img: require('../img/exhibitors/TomFrancis.jpg'),
	name: 'Tom Francis',
	url: 'https://www.reflextheatre.co.uk/fish',
}, {
	// 57
	img: require('../img/exhibitors/VaultsofVaarn.jpg'),
	name: 'Vaults of Vaarn',
	url: 'https://www.vaultsofvaarn.com',
}, {
	// 58
	img: require('../img/exhibitors/HuffNoMore.png'),
	name: 'Huff No More',
	url: 'https://www.huffnomore.co.uk',
}, {
	// --
	img: require('../img/exhibitors/Outclaw.png'),
	name: 'Outclaw',
	url: 'https://linktr.ee/mossmonkeygames',
}].sort((a, b) => a.name.replace(/^(The|A) /, "") > b.name.replace(/^(The|A) /, "") ? 1 : -1);

import backgroundPrg from '../img/background-get-program.jpg';
const OpenProgramButton = () => {
	const [hover, setHover] = useState(false);

	const styles = {
		normal: {
			'font-family': 'Planet Gamers',
			'font-size': 40,
			'text-align': 'center',
			'text-decoration': 'none',
			'font-weight': 'normal',
			color: palette.primary,
			'min-height': '1em',
			width: '9em',
			'max-width': '95%',
			'padding': '0.5em 0em 0.3em 0em',
			background: `rgba(255, 255, 255, 0.9)`,
			cursor: 'pointer',
			display: 'block',

			'-webkit-user-select': 'none',
			'-moz-user-select': 'none',
			'-ms-user-select': 'none',
			'user-select': 'none',

			transition: 'color 0.5s',
		},
	};
	styles.hover = {
		...styles.normal,
		color: palette.primary2,
	};
	const style = hover ? styles.hover : styles.normal;

	const backstyle = {
		display: 'flex',
		'justify-content': 'center',
		'flex-direction': 'column',
		'align-items': 'center',
		'min-height': 80,
		padding: '20px 0px',

		width: '100%',
		height: '100%',
		background: `url(${backgroundPrg}) no-repeat center center ${palette.accent}`,
		'background-size': 'cover',
	};
	return html`<div style=${backstyle}>
		<a
			style=${style}
			onMouseOver=${() => setHover(true)}
			onMouseLeave=${() => setHover(false)}
			href="https://www.norwichgames.uk/files/2024/program.pdf"
			target="_blank"
		>
			2024 ProgrAm
		</a>
	</div>`
};

export default () => {
	const nextStyle = {
		color: palette.secondary,
		'font-family': 'Planet Gamers',
		'font-size': 24,
	};

	const locationFrameStyle = {
		display: 'block',
		width: '100%',
		height: 400,
	};

	const socialLineStyle = {
		display: 'flex',
		'justify-content': 'center',
		'align-items': 'center',
		'flex-direction': 'row',
		'flex-wrap': 'nowrap',
		'text-align': 'center',
		gap: 20,
		'margin-top': 20,
	};

	const centerStyle = { width: '100%' };

	const promoReelStyle = {
		width: "100%",
		'aspect-ratio': '1280 / 720',
	};

	return html`
		<${Band} />
		<${Section} bigbanner backgroundImage=${homeHead}>
			<center style=${centerStyle}>
				<${Shield} width=180 />
				<${LogoText} />
				<p style=${nextStyle}>Saturday 24th of<br />August 2024</p>
				<div style=${socialLineStyle}>
					<${SocialFacebook} />
					<${SocialInstagram} />
				</div>
			</center>
		</${Section}>
		<${BuyTicketsButton} />
		<${OpenProgramButton} />
		<${Section} alt>
			<iframe
				style=${promoReelStyle}
				src="https://www.youtube.com/embed/jSo9FJqpE-g?si=14ee3gPP02-jrSw0"
				title="YouTube video player"
				frameborder="0"
				allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			></iframe>
		</${Section}>
		<${Section}>
			<iframe
				style=${locationFrameStyle}
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1320.359311176773!2d1.2919001249629212!3d52.631982822085504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d9e3dde1e64db3%3A0xd1b0fe27355f0a51!2sDuke%20Street%20-%20Norwich%20University%20of%20the%20Arts!5e0!3m2!1sen!2suk!4v1704217340250!5m2!1sen!2suk"
				frameborder="0"
				scrolling="no"
				marginheight="0"
				marginwidth="0"
			></iframe>
		</${Section}>
		<${Section}>
			<${SectionHead} text="PArtnErs" />
			<${ImageList} items=${partners} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="CharIty PArtnErs" />
			<${ImageList} items=${charities} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="Sponsors" />
			<${ImageList} items=${sponsors} width=${200} justifyContent="space-evenly" />
		</${Section}>
		<${Section}>
			<${SectionHead} text="ExhIbIt­ors" />
			<${ImageList} items=${exhibitors} background="white" minItems=${3} keepSquare />
		</${Section}>
		<${Band} />
	`;
};

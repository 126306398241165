import { h } from 'preact';
import htm from 'htm';

import { Section, SectionHead, SectionNarrow } from '../molecules/section';
import { SocialFacebook, SocialInstagram, urlFB } from '../atoms/social-media';
import { ItemTile, ItemTileCard, ItemTileHead } from '../molecules/item-tile';
import { palette } from '../branding';

import whatsOnHead from './whatson-head.jpg';
import orction from './orction.jpg';
import openGaming from './whatson-open-gaming.jpg';
import imgTradeHall from './whatson-tradehall.jpg';
import imgRPGs from './whatson-rpgs.jpg';
import tbfLogo from './tbfLogo.png';

const html = htm.bind(h);

const rpgList = () => {
	const rpgEvents = [{
		timeslot: "Norwich University of the Arts (11Am - 3pm)",
		games: [{
			host: "Sam",
			system: "Lancer",
			name: "Learning Lancer",
			players: 4,
			description: `Lancer is a tabletop role playing game centered on shared narratives, customizable mechs, and the pilots who crew them.

Set many thousands of years from the present day, Lancer imagines a future where a survivor humanity has spread to the stars after weathering terrible ecological collapse on Earth -- the end of the Anthropocene as a consequence of unrestrained consumption and poor stewardship.`,
			minAge: 15,
		}, {
			host: "Vanessa",
			system: "My Little Pony (Essence20)",
			name: "Stealing Thunder",
			players: 6,
			description: `Ponyville is getting ready for one of the biggest festivals of the year, complete with a parade and an airshow put on by Rainbow Dash and the Wonderbolts! But little do they know, a dark and gloomy force coming from Everfree Forest is going to put a dampener on their plans...

Play as brand new ponies interacting with the Mane 6 as you protect Ponyville and save the day! The My Little Pony Essence 20 system is a little bit simpler, so it's the perfect intro for anyone wanting to play any of Renegade's Hasbro games (Transformers, GI Joe, Power Rangers). This game will be suitable for everyone, but children should be accompanied by a trusted adult.`,
			minAge: 8,
		}, {
			host: "Sean Smith",
			system: "Quarrel + Fable",
			name: "Dungeon #23",
			players: 6,
			description: `The tincture shipment was expected one week back and we've just found the chirurgeon's wagon in the foothills outside of Kinstow. The trackers say her footprints lead to a strange and empty village away from any roads. YOU have been tasked with rescuing her ─ or her body if it is already too late.

QUARREL + FABLE is light-rules, heavy-British. Every attack hurts someone and it's the players ─ not their characters ─ who memorise the spells.`,
			minAge: 12,
		}, {
			host: "Carol Tierney",
			system: "Rivers of London",
			name: "Nightnurse",
			players: 6,
			description: `The players are called to investigate a strange death in the local hospital...`,
			minAge: 12,
		}, {
			host: "Gary Tierney",
			system: "Doctor Who",
			name: "Project Teal",
			players: 6,
			description: `The players are members of UNIT tasked with planting a temporal beacon as part of new monitoring system to prevent incursions.`,
			minAge: 12,
		}],
	}, {
		timeslot: "Norwich Castle (6pm - 10pm)",
		games: [{
			host: "BECMI Berserker",
			system: "BECMI Dungeons and Dragons",
			name: "B10 Night's Dark Terror - the Siege of Sukiskyn",
			players: 6,
			description: `The uncharted tracts of the Dymrak Forest conceal horrors enough to freeze the blood of civilized folk. Those who have ventured there tell how death comes quick to the unwary—for the woods at night are far worse than any dungeon. But you are adventurers, veterans of many battles, and the call of the wild is strong. Will you answer the call, or are you afraid of the dark terrors of the night?`,
			minAge: 12,
		}, {
			host: "David Gallico",
			system: "Dungeon Crawl Classics",
			name: "The Bloody Wrath of Countess Mekula",
			players: 6,
			description: `400 years ago, the evil vampiress Count Mekula's reign of terror was finally brought to and end. However, she has returned seeking veangence! The PCs only have until sunset to penetrate the crypt beneath the ruins of her castle and stop her. Can these stalwart villagers prevail against a powerful vampiress, or will Mekula triumph and succeed in her plans for world domination?`,
			minAge: 12,
		}, {
			host: "Edward",
			system: "D&D 5e (Pre-2024 update)",
			name: "Mysteries of Haven Harbour",
			players: 5,
			description: `A darkness looms over the peaceful village of Haven Harbour. Fishing boats have been going missing, their crew lost without a trace, and strange figures have been seen about the village at night, creeping in the darkness when all should be in their beds.
As their losses mount, and fear grows in the streets, the village’s Elder has put out the word for anyone who could help investigate these strange goings on to gather, with a reward in gold for whoever can solve this crisis.
And so, your adventuring group has arrived to investigate the worries of this small fishing village, for honour, for justice, for curiosity, or even simply for money. Will you be able to discover the source of this villages’ problems, or will you be another victim of the Mysteries of Haven Harbour?`,
			minAge: 16,
		}, {
			host: "Fran",
			system: "D&D 5e",
			name: "The Greyheart Caper",
			players: 5,
			description: `Surrounded by a sea of midnight sand you will find the jewel of the Andrican Empire the mage city of Thalindor. Thanlindor is famous for its 8 towers of deep jewel-cloured glass spires which at night reflect and refract the warm light of the bustling city streets that weave around their bases. The streets hum with the industry of both magical and mundane means. 

At first glance, this city seems at rest the morning light bathing its famous spires in warm sunlight. No signs of a populous in flux with questions and rumours filling their heads of who could have possibly done this…. But what is the ‘this’ they are yet to start discussing? For that, we must rewind to the 10 hours previously. You all stand in the nearby alley holding invitations to Archmage Syliveris Greyheart's grand ball. Secured for you by your benefactor who has asked that you attend this party and steal the Archmage Greyheart's spellbook. An esoteric blue book inscribed with silver runes that softly glow with an everlasting subtle enchantment. So what does the crew assembled in this alley do first?`,
			minAge: 12,
		}, {
			host: "3DM",
			system: "D&D 5e",
			name: "The Battle of Rusulak",
			players: 6,
			description: `A siege is coming! The characters must face the prospect of prolonged conflict, and preparations must begin.

Defend your own castle or take the fight to your enemies? Make choices that will sway the outcome of battle!`,
			minAge: 12,
		}, {
			host: "Andrew",
			system: "D&D 5e",
			name: "Dragon Slaying 102",
			players: 6,
			description: `Stormcliff Castle.

Once the home of a mad mage who explored the magic capabilities of lightning, it has now has a new master.

Rumours abound that Kriezan of the Sparktongue, a powerful dragon specialising in Lightning Magics has taken residence of the castle and is trying to unlock secrets of magic that have been long since forgotten.
You and your fellow adventurers have been tasked with eliminating this creature at any cost, but time is running short and the biggest thunderstorm in recent history is due to hit at any moment.
Will you succeed in time and stop this Great Beast’s plan? Or will you fail and the entire coastline be reduced to ashes!

Howdy folks, this is a Dungeons and Dragons 5E game, aimed at a slightly higher level so some experience with the game is recommended! Character sheets will be provided, and although I’m keeping a lot under wraps, much like ‘Strahd must die, Tonight!’ you and your fellow adventurers will be under a strict time constraint!`,
			minAge: 12,
		}, {
			host: "Frank Reding",
			system: "Hypertellurians",
			name: "Capes and Cloaks and Cowls and a Park",
			players: 5,
			description: `Riblerim the Unsure, Master Diviner, in his benevolent, yet eccentric prime, created a wizard's theme park—that's a theme park by a wizard, not strictly speaking for wizards. Though the latter were certainly welcome too.

Then came the Great Needle Pusher Purge. Seamstresses and tailors, long suspected of sorcery, one and all, were persecuted and driven away. Predictably, the Great Needle Pusher Purge was followed by the Great Wearing of Nothing but Rags, but that's not so important. Unwilling to idly stand by, Riblerim created the Costumiers with Latent Arcane Magicks Refuge Initiative & Motivation Scheme (CLAMRIMS, for short), seeking out freethinking clothiers and modistes, and sheltering them in his wondrous realm.

All was nice and pleasant as it could be, but then Riblerim disappeared.

Fifty years he has been missing, and the eldritch realm, with its arcane creations and thaumaturgic costumiers, has been unraveling. Not all is nice and pleasant anymore.`,
			minAge: 12,
		}, {
			host: "Andrew Walter",
			system: "OSRIC / AD&D 1e",
			name: "Many Gates of Gann",
			players: 5,
			description: `For weeks now bands of brutal hyena-men have been the scourge of the surrounding villages, carrying away victims for unknown purposes to their cliffside citadel. Earthquakes shake the land, and ominous portents cloud the minds and reason of even the most capable warriors.

Three weeks ago an exploration party left for the hyena-man citadel in the the cliffside, but never returned.The outer gate remains open, two forbidding ape-head statues flanking the dark entryway. Your party now ventures within, in search of revenge, riches...or mysteries unbeheld by mortal minds. 

Only tactics, heroism and cunning can win the day. Will you traverse...THE MANY GATES OF THE GANN?`,
			minAge: 12,
		}, {
			host: "Violet Baker",
			system: "Pathfinder 2e",
			name: "The Lady in Waiting",
			players: 6,
			description: `A soft rain falls from a darkened sky, watering the barren earth among the broken remains of this once-heinous structure. Suddenly, a hand bursts from beneath the rubble, reaching and grasping skyward. With effort, the dirty and bedraggled person pulls themselves from beneath the stony monastery ruins, gripping their hard-earned riches tightly. The mortal wound on their neck drips a wicked ichor as the rainwater washes away the mud and horrors they experienced deep below.

The slumping figure makes their way slowly back toward home, tightly clutching their hard-earned prize. Unaware of the terrors they have just unleashed into the waking world.`,
			minAge: 16,
		}, {
			host: "Mat",
			system: "World of Darkness",
			name: "Sabbat Games",
			players: 6,
			description: `Welcome to the Sabbat games. You awake in a dark room, with a few others with no memory of how you got here. Will you be able to survive the night, the rooms, the other players and your own morale codes. But most importantly are you able to survive the game.

The premises is a Saw survival game, are you able to survive the night.

So who wants to play a game.`,
			minAge: 15,
		}, {
			host: "Xorron",
			system: "Conan 2d20",
			name: "Hunting the Firebrand",
			players: 6,
			description: `You are on board the Green Dragon and are just a few days behind the notorious pirate ship Firebrand - will you catch them and bring them to justice?`,
			minAge: 12,
		}, {
			host: "Carol Tierney",
			system: "Call of Cthulhu",
			name: "Poor Rich Man",
			players: 6,
			description: `The investigators are invited to Castle Sable to visit an old friend, but their welcome doesn’t go as expected.`,
			minAge: 12,
		}, {
			host: "Gary Tierney",
			system: "Star Wars D6",
			name: "Tatooine or Bust",
			players: 6,
			description: `The players are a covert ops team sent in to discretely assist a potential new ally for the Rebel Alliance.`,
			minAge: 12,
		}],
	}];

	const divStyle = {
		width: '100%',
		'text-align': 'left',
	}
	const timeslotStyle = {
		'font-family': 'Planet Gamers',
		'font-size': 24,
		color: palette.primary2,
	}
	const blockStyle = {
		'margin': '30px 0px',
	}
	const gameStyle = {
		display: 'flex',
		'flex-direction': 'row',
		'flex-wrap': 'wrap',
		'justify-content': 'space-between',
		'margin-bottom': 40,
		padding: 10,
		background: palette.primary_lightened,
	}
	const bitStyleL = {
		'min-width': '51%',
	}
	const bitStyleR = {
		width: '12em',
	}
	const sectionName = {
		'font-family': 'Planet Gamers',
		color: palette.primary2,
		'font-size': 14,
	}
	const descriptionStyle = {
		'white-space': 'pre-line',
	}
	return html`<div style=${divStyle}>
		${rpgEvents.map((event) => html`<div style=${blockStyle}>
			<center><h2 style=${timeslotStyle}>${event.timeslot}</h2></center>
			${event.games.map((game) => html`<div style=${gameStyle}>
				<span style=${bitStyleL}><span style=${sectionName}>System:</span> ${game.system}</span>
				<span style=${bitStyleR}><span style=${sectionName}>Host:</span> ${game.host}</span>
				<span style=${bitStyleL}><span style=${sectionName}>Name:</span> ${game.name}</span>
				<span style=${bitStyleR}><span style=${sectionName}>Players:</span> ${game.players}</span>
				<span style=${bitStyleL}><span style=${sectionName}>Age:</span> ${game.minAge || 12}+</span>
				<span style=${bitStyleR}><span style=${sectionName}></span></span>
				<span style=${bitStyleL}><span style=${sectionName}>Description:-</span>
					<br />
					<span style=${descriptionStyle}>${game.description} </span>
				</span>
			</div>`)}
		</div>`)}
	</div>`
};


const innerImgStyle = {
	'max-width': '100%',
};

const tilePhotoImgStyle = {
	...innerImgStyle,
	'margin-top': '1em',
};

const socialLineStyle = {
	display: 'flex',
	'justify-content': 'center',
	'align-items': 'center',
	'flex-direction': 'row',
	'flex-wrap': 'nowrap',
	'text-align': 'center',
	gap: 20,
	'margin-top': 20,
};

const placeAndTime = ({place, time}) => {
	const style = {
		display: 'flex',
		'justify-content': 'center',
		'flex-direction': 'column',
		'align-items': 'center',
	};

	return html`<div style=${style}>
		<div><b>${place}</b></div>
		${time && html`<div><b>${time}</b></div>`}
	</div>`
};

export default () => html`
	<${Section} banner backgroundImage="${whatsOnHead}">
		<${SectionHead} text="What's On" shadow />
	</${Section}>
	<${ItemTile}>
		<${ItemTileCard}>
			<${ItemTileHead} text="Trade Hall" />
			<${placeAndTime} place="Norwich University of the Arts" time="9am-4pm" />
			<img style=${tilePhotoImgStyle} src=${imgTradeHall} />
			<p>Our large trading space. Here you will find exhibitors looking to sell, playtest, or promote board games, tabletop role-playing games, art and extras. Amongst our exhibitors are local game designers, artists, dice makers, and many more!</p>
		</${ItemTileCard}>
		<${ItemTileCard}>
			<${ItemTileHead} text="Role­playing Games" />
			<${placeAndTime} place="Norwich University of the Arts & Norwich Castle" time="11am-3pm & 6pm-10pm" />
			<img style=${tilePhotoImgStyle} src=${imgRPGs} />
			<p>Our roleplaying games this year span Norwich University of the Arts and Norwich Castle. A wide variety of games are on offer, providing great opportunities to learn a new system or setting!</p>
			<center><a href="#roleplaying">A full list of games is available here!</a></center>
		</${ItemTileCard}>
		<${ItemTileCard}>
			<${ItemTileHead} text="Orction" />
			<${placeAndTime} place="The Oak Circus Centre" time="9am-4pm" />
			<img style=${tilePhotoImgStyle} src=${orction} />
			<p>The wonderful ORCTIONEER Elliot Symonds is returning for NGC '24. The Orction will - as always - offer an excellent opportunity to bid on both vintage and new games, and bag yourself a bargain!</p>
		</${ItemTileCard}>
		<${ItemTileCard}>
			<${ItemTileHead} text="Open Gaming Area" />
			<${placeAndTime} place="The Oak Circus Centre" time="9am-9pm" />
			<img style=${tilePhotoImgStyle} src=${openGaming} />
			<p>There will be lots of tables available to sit and play games, conveniently located near the bar and food vendors. This table space will be open until late, so you can play as long as you like!</p>
			<p>Expect to see our staff here in the evening, finally getting a chance to relax and play some games ourselves!</p>
		</${ItemTileCard}>
		<${ItemTileCard} just>
			<${ItemTileHead} text="Bring & Buy" />
			<${placeAndTime} place="The Oak Circus Centre" time="9am-6pm" />
			<p>Here you can drop off your preloved games, books and cards and sell them to new loving homes. Or you can come along and buy some second hand games for very reasonable prices. If you've ever attended a tabletop games convention you'll know this is a popular feature -- and for good reason.</p>
		</${ItemTileCard}>
		<${ItemTileCard}>
			<${ItemTileHead} text="Live Events" />
			<${placeAndTime} place="Norwich University of the Arts & Norwich Castle" time="10am-10pm" />
			<p>For the first time we are providing a schedule of live events all day and late into the evening.</p>
		</${ItemTileCard}>
		<${ItemTileCard} just>
			<${ItemTileHead} text="Food & Drink" />
			<p>We'll again be teaming with <a href="https://www.sliceanddice.cafe" target="_blank">Slice + Dice</a> to make sure food will be available throughout the day. They'll be providing delicious food suitable for a broad range of diets and in line with our environmental commitments.</p>
			<p>There is also a well-stocked professional bar that will be serving all day and late into the evening to keep thirsty gamers refreshed. 🍺 🍻 🍺</p>
		</${ItemTileCard}>
		<${ItemTileCard}>
			<${ItemTileHead} text="More" />
			<p>We have much more to announce! The best place to be the first to find out more is at our social media accounts.</p>
			<div style=${socialLineStyle}>
				<${SocialFacebook} />
				<${SocialInstagram} />
			</div>
		</${ItemTileCard}>
	</${ItemTile}>
	<${Section} alt id="roleplaying">
		<${SectionHead} text="Roleplaying Games" smallFont />
		<center><a href="#">BACK TO TOP</a></center>
		<${SectionNarrow}>
			<${rpgList}/>
		</${SectionNarrow}>
		<center><a href="#">BACK TO TOP</a></center>
	</${Section}>
`;

import { h } from 'preact';
import htm from 'htm';

import logoFB from './logo-fb.png';
import logoIN from './logo-in.png';

const html = htm.bind(h);

const urlFB = 'https://www.facebook.com/NorwichGamesCon';
const urlIG = 'https://www.instagram.com/norwichgamescon';

const style = {
	width: 32,
	height: 32,
};

const SocialFacebook = () => html`<>
	<a href=${urlFB} target="_blank">
		<img src=${logoFB} style=${style}>
	</a>
</>`;

const SocialInstagram = () => html`<>
	<a href=${urlIG} target="_blank">
		<img src=${logoIN} style=${style}>
	</a>
</>`;

export { SocialFacebook, SocialInstagram, urlFB, urlIG };

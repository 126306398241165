const palette = {
	primary: '#2c364f', // Blue
	primary_lightened: '#354672',
	primary2: '#e7ae23', // Gold
	primary2_lightened: '#f9d47b',
	secondary: '#f9faff',
	accent: '#ffffff',
	dark: '#101010',
	disabled: '#aaa',
};

export { palette };

import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useRouter } from 'preact-router';
import htm from 'htm';

import { navigateTo } from '../../routing';
import { palette } from '../../branding';
import { Shield } from '../../atoms/logo';
import { SocialFacebook, SocialInstagram } from '../../atoms/social-media';
import routes from '../../routes';

const html = htm.bind(h);

const foldWidth = 800;

const logoDivStyle = {
	position: 'absolute',
	left: 20,
	top: 10,
	width: 58,
	height: 80,
	cursor: 'pointer',
};

const burgerDivStyle = {
	position: 'absolute',
	right: 20,
	top: 10,
	width: 80,
	height: 80,
	cursor: 'pointer',

	display: 'flex',
	'justify-content': 'center',
	'align-items': 'center',
	'flex-wrap': 'nowrap',
};

const burgerLineStyle = {
	stroke: palette.primary,
	'stroke-width': 4,
	'stroke-linecap': 'round',
};

const optionsDivStyle = {
	position: 'absolute',
	top: 120,
	width: '100%',
	height: 'calc(100% - 120px)',
	'overflow-y': 'auto',
	'scrollbar-gutter': 'stable',

	display: 'flex',
	'align-items': 'center',
	'flex-direction': 'column',
	'flex-wrap': 'nowrap',
	'text-align': 'center',
};

const socialLineStyle = {
	display: 'flex',
	'justify-content': 'center',
	'align-items': 'center',
	'flex-direction': 'row',
	'flex-wrap': 'nowrap',
	'text-align': 'center',
	gap: 20,
};

const optionsLineStyle = {
	position: 'absolute',
	right: 20,
	top: 10,
	height: 80,

	display: 'flex',
	'align-items': 'center',
	'flex-wrap': 'nowrap',
	'text-align': 'center',
};

const menuBreak = ({ left, right, up, down, colour = palette.primary }) => {
	const style = {
		width: 10,
		height: 10,
		background: colour,
		'margin-right': right ? 20 : 0,
		'margin-left': left ? 20 : 0,
		'margin-top': up ? 20 : 0,
		'margin-bottom': down ? 20 : 0,
	};
	return html`<div style=${style}></div>`
}

const routeLink = ({
	to = '/',
	fontSz = 40,
	onClickCB,
	text,
	disabled,
	external,
	row,
}) => {
	const styles = {
		normal: {
			'font-family': 'Planet Gamers',
			'font-size': fontSz + 'px',
			cursor: 'pointer',
			color: palette.primary,
			'margin-top': row ? 0 : 20,
			'margin-left': row ? 0 : 20,
			'margin-bottom': row ? -5 : 15, // Font is a bit weird for this
			'margin-right': row ? 0 : 20,
			'-webkit-user-select': 'none',
			'-moz-user-select': 'none',
			'-ms-user-select': 'none',
			'user-select': 'none',
			'text-decoration': 'none',
			'font-weight': 'normal',

			transition: 'color 0.5s',
		},
	};
	styles.hover = {
		...styles.normal,
		color: palette.primary2,
	};

	if (disabled) {
		styles.normal.cursor = 'default';
		styles.normal.color = palette.disabled;
		styles.hover = styles.normal;
	}

	const [hover, setHover] = useState(false);
	const currentPath = useRouter()[0].path || useRouter()[0].url;
	const pathMatch = currentPath === to;
	const style = pathMatch || hover ? styles.hover : styles.normal;

	if (external) {
		return html`<a
			onClick=${onClickCB}
			onMouseOver=${() => setHover(true)}
			onMouseLeave=${() => setHover(false)}
			style=${style}
			disabled=${disabled}
			href=${disabled ? undefined : to}
			target="_blank"
		> ${text} </a>`;
	}

	const onClick = () => {
		if (disabled) return;
		navigateTo(to);
		if (onClickCB) onClickCB();
	};

	return html`<div
		onClick=${onClick}
		onMouseOver=${() => setHover(true)}
		onMouseLeave=${() => setHover(false)}
		style=${style}
	>
		${text}
	</div>`;
};

const Menu = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [open, setOpen] = useState(false);
	const needFold = width <= foldWidth;

	if (!needFold && open) {
		setOpen(false);
	}

	useEffect(() => {
		document.body.style.overflow = open ? 'hidden' : 'auto';
	}, [open]);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const logoClick = () => {
		navigateTo('/');
		setOpen(false);
	};
	const burgerClick = () => setOpen(!open);
	const selectionMade = () => setOpen(false);

	let burgerPos = [
		{ x1: 2, y1: 2, x2: 38, y2: 2, },
		{ x1: 2, y1: 20, x2: 38, y2: 20, },
		{ x1: 2, y1: 38, x2: 38, y2: 38, },
	];

	const style = {
		height: 100,
		width: '100%',
		'box-shadow': '0 2px 4px 0 rgba(0,0,0,.2)',
		'z-index': 99,
		position: 'fixed',
		top: 0,
		overflow: 'hidden',
		'overscroll-behavior': 'contain',
		transition: 'height 0.5s',

		background: palette.secondary,
	};
	if (open) {
		style.height = '100vh';

		burgerPos = [
			{ x1: 2, y1: 2, x2: 38, y2: 38, },
			{ x1: 2, y1: -5, x2: 38, y2: -5, },
			{ x1: 2, y1: 38, x2: 38, y2: 2, },
		];
	}

	const unfoldedMenuWrapStyle = {
		width: 700,
		display: 'flex',
		'flex-wrap': 'wrap',
		'justify-content': 'flex-end',
		'align-items': 'center',
		'gap': '10px 30px',
	};

	return html`<nav style=${style}>
		<div style=${logoDivStyle} onClick=${logoClick}>
			<${Shield} height="80" alt />
		</div>
		${needFold && html`<div style=${burgerDivStyle} onClick=${burgerClick}>
			<svg viewBox="0 0 40 40" width="40" height="40">
				<line
					x1="${burgerPos[0].x1}"
					y1="${burgerPos[0].y1}"
					x2="${burgerPos[0].x2}"
					y2="${burgerPos[0].y2}"
					style=${burgerLineStyle}
				/>
				<line
					x1="${burgerPos[1].x1}"
					y1="${burgerPos[1].y1}"
					x2="${burgerPos[1].x2}"
					y2="${burgerPos[1].y2}"
					style=${burgerLineStyle}
				/>
				<line
					x1="${burgerPos[2].x1}"
					y1="${burgerPos[2].y1}"
					x2="${burgerPos[2].x2}"
					y2="${burgerPos[2].y2}"
					style=${burgerLineStyle}
				/>
			</svg>
		</div>`}
		${needFold && html`<div style=${optionsDivStyle}>
			${routes.map((route) => html`
				<${routeLink} to=${route.path} onClickCB=${selectionMade} text=${route.text} disabled=${route.disabled} external=${route.external} />
			`)}
			<${menuBreak} down colour='rgba(0, 0, 0, 0)' />
			<div style=${socialLineStyle}>
				<${SocialFacebook} link="NorwichGamesCon" />
				<${SocialInstagram} link="norwichgamescon" />
			</div>
		</div>`}
		${needFold || html`<div style=${optionsLineStyle}>
			<div style=${unfoldedMenuWrapStyle}>
				${routes.map((route) => html`
					<${routeLink} to=${route.path} text=${route.text} disabled=${route.disabled} external=${route.external} fontSz=32 row />
				`)}
				<${SocialFacebook} link="NorwichGamesCon" />
				<${SocialInstagram} link="norwichgamescon" />
			</div>
		</div>`}
	</nav>`;
};

export { Menu };
